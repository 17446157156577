/*
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-20 18:23:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/common/utils.js
 */
let CryptoJS = require("crypto-js")
// 手机号校验规则
export const validatePhone = (rule, value, callback) => {
  //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
  let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/
  if (value.length == 0) {
    callback()
  } else {
    if (!regs.test(value)) {
      callback([new Error("请输入格式正确的手机号码")])
    } else {
      callback()
    }
  }
}
// 手机号|座机号校验规则
export const validateTelePhone = (rule, value, callback) => {
  //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
  let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/
  let regs1 = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
  if (value.length == 0) {
    callback()
  } else {
    if (!(regs.test(value) || regs1.test(value))) {
      callback([
        new Error(
          "请输入格式正确的联系电话，座机格式为XXX（区号）-XXXXXXXX（区段号码）"
        )
      ])
    } else {
      callback()
    }
  }
}
export const validatePassword = (rule, value, callback) => {
  let regs = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,20}$/
  if (value.length == 0) {
    callback([new Error("密码不能为空")])
  } else {
    if (!regs.test(value)) {
      callback([new Error("密码组合不符合要求")])
    } else {
      callback()
    }
  }
}
// 加密函數
const KEY = CryptoJS.enc.Utf8.parse("1234567890ABCDEF") //  密钥        长度必须为16位
const IV = CryptoJS.enc.Utf8.parse("123456") //偏移量
export const encrypt = (str, keyStr, ivStr) => {
  let key = KEY
  let iv = IV

  if (keyStr && ivStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr)
    iv = CryptoJS.enc.Utf8.parse(ivStr)
  }
  let srcs = CryptoJS.enc.Utf8.parse(str)
  var encrypt = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC, //这里可以选择AES加密的模式
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Base64.stringify(encrypt.ciphertext)
}
// url校验
export const validateUrl = (rule, value, callback) => {
  //校验以http://、https://开头的url
  let pattern = /(http|https):\/\/([\w.]+\/?)\S*/
  value = "" + value
  if (!pattern.test(value)) {
    callback([new Error("URL格式不正确")])
  } else {
    callback()
  }
}
// url校验
export const validateRedirectUrl = (rule, value, callback) => {
  //校验以http://、https://开头的url
  let pattern = /(http|https):\/\/([\w.]+\/?)\S*/
  let valueArr = ("" + value).split("\n").filter(item => item !== "")
  if (valueArr.find(item => !pattern.test(item))) {
    callback([new Error("URL格式不正确")])
  } else {
    callback()
  }
}
