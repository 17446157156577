import axios from "./axios.js"
// import qs from 'qs'

const createAPI = (url, method, config, vconfig = {}) => {
  config = config || {}
  return axios({
    url,
    method,
    ...config,
    vconfig
  })
}

export default createAPI
