/*
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-18 09:20:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/common/axios.js
 */
import axios from "axios"
import Loading from "./loading.js"
import { Message } from "element-ui"
// import { ErrorMessage } from "./errorMessage.js"
const _axios = axios.create({
  baseURL: "",
  timeout: 900000 // request timeout
})

// 添加请求拦截器
_axios.interceptors.request.use(
  function(config) {
    if (config.vconfig && !config.vconfig.neverLoading) {
      Loading.start()
    }
    if (process.env.NODE_ENV === "development") {
      config.headers["Authorization"] =
        "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4SUpLOWJzM0pRNUctcU5jd18wZkhJUHpWTWFqVTNYLXlTTkFfdGs5Y2ZBIn0.eyJleHAiOjE2NDIwNjg2ODAsImlhdCI6MTYzOTU2MzA4MCwianRpIjoiYjNiYTdjNzUtMDkzNS00ZTA2LWIxZjQtMjk4NzU0MjI1NjAwIiwiaXNzIjoiaHR0cHM6Ly9xaWthbi5pZHMudGVzdHguZnp5dW4uaW8vYXV0aC9yZWFsbXMvcWlrYW4iLCJhdWQiOiJxaWthbi1wb3J0YWwiLCJzdWIiOiIwODJhN2QzYS1kYWFmLTRmODgtODQyNS1jZTc4NDY5ODBjNGEiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJxaWthbi1wb3J0YWwiLCJzZXNzaW9uX3N0YXRlIjoiNDE5ZTRkMTEtYmQ4MS00MjI1LTk0ZWYtYzRhZGVlNWIwMmYxIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vbWFnYXppbmUtaWRzLmRldjYuZnp5dW4uaW8iXSwicmVzb3VyY2VfYWNjZXNzIjp7InFpa2FuLXBvcnRhbCI6eyJyb2xlcyI6WyJ2aXNpdG9yIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoi5L2c6ICF5LiAIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYXV0aG9yMSIsImZhbWlseV9uYW1lIjoi5L2c6ICF5LiAIiwiZW1haWwiOiJhdXRob3IxQDEyNi5jb20ifQ.R09MDCegnLdtTMwIgHnI_zak-2iyG7anaPq7xDP4yuHQYjdgDGka9tqUPr9C9jos4V3l2u_ame4h5rtlYpX61o4fRq5sG0g-_lU8gyCR9NkPu4uGQAjPILEaEsxivNRjBiTwuIl_RRwrW2b2sPBop1E-2P3C1CHcj55OktF-u9mis9wUz2sdZAY2KhVXcI6k2eH0Y9jp5A2teqdhQAhgrph39P__VHVAkhfMEIpzgflLN2VL3_zKMczEpz_uyWNFBAf5dys-nNJm-7COuD3mGkC4Az9Ld39n3BQtjz660sZpO0YPW3qj5Nq6Uuqhxyhnyo6I3G7z7L71lkuI5nO_ng"
    }
    return config
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
_axios.interceptors.response.use(
  response => {
    // 没有loading的请求，不需要再关闭loading了
    if (response.config.vconfig && !response.config.vconfig.neverLoading) {
      Loading.close()
    }
    if (
      (!response.data || response.data.status !== 0) &&
      response.data.status !== 5001 &&
      response.data.status !== 5002 &&
      response.data.status !== 5008 && 
      response.data.status !== 999 && 
      response.data.status !== 6004 &&
      response.data.status !== 5007 &&
      response.data.status !== 6002 &&
      response.data.status !== 1
    ) {
      Message.error({
        message: response.data.data
      })
    }
    return response.data
  },
  error => {
    Loading.close()
    // 对响应错误做点什么，比如400、401、402等等
    if (error && typeof error.response === "undefined") {
      Message.error({
        message: "登录已过期，请重新登录"
      })
      setTimeout(() => {
        // window.location.reload()
        // window.location.href = "/oauth/logout?redirect=" + location.href
      }, 1000)
    }
    if (error && error.response) {
      if (
        error.response.status !== 403 &&
        error.response.status !== 5001 &&
        error.response.status !== 5002
      ) {
        // Message.error({
        // })
      }
    }
    // return Promise.reject(error)
  }
)

export default _axios
