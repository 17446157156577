<!--
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-25 14:13:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/views/enterprise/index.vue
-->
<template>
  <div class="register">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>账号创建失败，请稍后重试。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <div class="register__top">
      <div>方正鸿云平台注册</div>
    </div>
    <el-dialog
      title="图形验证"
      :visible.sync="imgDialogVisible"
      width="500px"
      :before-close="handleClose">
      <span class="dialog-body" v-if="emailImgVerify">
        <img class="imgVerify__img" :src="emailVerifyData.url" alt="">
          <span class="imgVerify__change" @click="getImageCode('email')">看不清？换一张</span>
          <div class="imgVerify__option">
            <p class="imgVerify__option--prompt">请选择正确答案</p>
            <span class="imgVerify__option--button" v-for="(item,index) in emailVerifyData.numbers" :key="index" @click="checkImageCode(item,'email')">{{item}}</span>
          </div>
      </span>
      <span class="dialog-body" v-if="phoneImgVerify">
        <img class="imgVerify__img" :src="phoneVerifyData.url" alt="">
          <span class="imgVerify__change" @click="getImageCode('phone')">看不清？换一张</span>
          <div class="imgVerify__option">
            <p class="imgVerify__option--prompt">请选择正确答案</p>
            <span class="imgVerify__option--button" v-for="(item,index) in phoneVerifyData.numbers" :key="index" @click="checkImageCode(item,'phone')">{{item}}</span>
          </div>
      </span>
    </el-dialog>
    <div class="register__body">
      <div class="register__body__back">
        <el-link icon="el-icon-d-arrow-left" @click="backLogin"
          >返回登录页</el-link
        >
      </div>
      <p class="register__body__journal--name">您正在注册为<span>《{{journalName}}》</span>的{{ role }}：</p>
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100px">
        <el-form-item label="真实姓名" prop="realName">
          <el-input placeholder="请填写真实姓名" v-model="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input placeholder="请输入邮箱地址" v-model="form.email" @input="saveEmail" @focus="focusEmail">
            <el-button
              slot="append"
              @click="getImageCode('email')"
              :disabled="sendEmailCode"
              ><span v-if="!sendEmailCode">发送验证码</span><span class="register__body__sended" v-else>{{`已发送(${sendEmailTime})`}}</span></el-button
            >
          </el-input>
          <p class="register__body__exist--email" v-if="emailExist">
            <span class="register__body__exist--email--prompt"
              >检测到邮箱在方正云平台注册过账号</span
            >, 可直接<span
              class="register__body__exist--email--login"
              @click="backLogin"
              >登录</span
            >
          </p>
        </el-form-item>
        <!-- <el-form-item class="imgVerify" prop="imgVerify" v-if="emailImgVerify">
          <img class="imgVerify__img" :src="emailVerifyData.url" alt="">
          <span class="imgVerify__change" @click="getImageCode('email')">看不清？换一张</span>
          <div class="imgVerify__option">
            <el-button v-for="(item,index) in emailVerifyData.numbers" :key="index" @click="checkImageCode(item,'email')">{{item}}</el-button>
          </div>
        </el-form-item> -->
        <el-form-item label="邮箱验证码" prop="emailCode">
          <el-input
            class="register__body__short"
            placeholder="请输入邮箱验证码"
            v-model="form.emailCode"
            @focus="clearEmailMessage"
          ></el-input>
          <p v-if="emailErrorMessage" class="register__body__error">
            邮箱验证码错误
          </p>
        </el-form-item>
        <el-form-item label="备用邮箱" prop="bankupEmail">
          <el-input placeholder="请输入备用邮箱" v-model="form.bankupEmail"></el-input>
        </el-form-item>
        <el-form-item label="手机号号码" prop="phone">
          <el-input
            placeholder="请输入手机号码"
            v-model="form.phone"
            @input="savePhone"
            @focus="focusPhone"
          >
            <el-button
              slot="append"
              @click="getImageCode('phone')"
              :disabled="sendPhoneCode"
              ><span v-if="!sendPhoneCode">发送验证码</span><span class="register__body__sended" v-else>{{`已发送(${sendPhoneTime})`}}</span></el-button
            >
          </el-input>
          <p class="register__body__exist--email" v-if="phoneExist">
            <span class="register__body__exist--email--prompt"
              >检测到手机在方正云平台注册过账号</span
            >, 可使用<span
              class="register__body__exist--email--login"
              @click="backLogin"
              >手机短信登录。</span
            >
          </p>
        </el-form-item>
        <!-- <el-form-item class="imgVerify" prop="imgVerify" v-if="phoneImgVerify">
          <img class="imgVerify__img" :src="phoneVerifyData.url" alt="">
          <span class="imgVerify__change" @click="getImageCode('phone')">看不清？换一张</span>
          <div class="imgVerify__option">
            <el-button v-for="(item,index) in phoneVerifyData.numbers" :key="index" @click="checkImageCode(item,'phone')">{{item}}</el-button>
          </div>
        </el-form-item> -->
        <el-form-item label="手机验证码" prop="verifyCode">
          <el-input
            class="register__body__short"
            placeholder="请输入手机验证码"
            v-model="form.verifyCode"
            @focus="clearPhoneMessage"
          ></el-input>
          <p v-if="phoneErrorMessage" class="register__body__error">
            手机验证码错误
          </p>
        </el-form-item>
        <el-form-item label="设置密码" prop="password" class="register__body__password">
          <el-input
            class="register__body__short"
            placeholder="请设置登录密码"
            v-model="form.password"
            show-password
          ></el-input>
          <span class="register__body__password--prompt"
            >长度为8-20个字符，必须包含大写字母、小写字母和数字，不允许有标点符号、空格和中文。</span
          >
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword" class="register__body__password">
          <el-input
            class="register__body__short"
            placeholder="请再次输入登录密码"
            v-model="form.rePassword"
            show-password
          ></el-input>
          <span class="register__body__password--prompt"
            >长度为8-20个字符，必须包含大写字母、小写字母和数字，不允许有标点符号、空格和中文。</span
          >
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="agreeChecked">阅读并接受</el-checkbox
          ><span class="register__body__agree" @click="lookAgree"
            >用户协议</span
          >和<span class="register__body__agree" @click="lookSecret"
            >隐私政策</span
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="register__body__password--btn"
            @click="submitForm('form')"
            >提交注册</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from "@/apis/user.js"
import { validatePassword, encrypt } from "@/common/utils.js"
export default {
  data() {
    const validaterePassword = (rule, value, callback) => {
      let regs = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,20}$/
      if (value.length == 0) {
        callback([new Error("密码不能为空")])
      } else {
        if (!regs.test(value)) {
          callback([new Error("密码组合不符合要求")])
        } else if (
          regs.test(value) &&
          this.form.password !== this.form.rePassword
        ) {
          callback([new Error("登录密码不一致。")])
        } else {
          callback()
        }
      }
    }
    return {
      url:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      form: {
        realName: "",
        email: "",
        emailCode: "",
        bankupEmail:"",
        phone: "",
        verifyCode: "",
        password: "",
        rePassword: ""
      },
      rules: {
        realName: [{ required: true, message: "请填写真实姓名", trigger: "blur" }],
        email: [
          { required: true, message: "请填写邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "邮箱格式错误",
            trigger: "blur"
          }
        ],
        bankupEmail: [
          { required: false, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "邮箱格式错误",
            trigger: "blur"
          }
        ],
        emailCode: [
          { required: true, message: "请填写邮箱验证码", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请填写手机号", trigger: "blur" },
          {
            message: "手机号格式错误",
            pattern: "^(1[0-9])\\d{9}$",
            trigger: "blur"
          }
        ],
        verifyCode: [
          { required: true, message: "请填写手机验证码", trigger: "blur" }
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur"
          }
        ],
        rePassword: [
          { required: true, validator: validaterePassword, trigger: "blur" }
        ]
      },
      emailImgVerify:false,
      phoneImgVerify:false,
      emailVerifyData:null,
      phoneVerifyData:null,
      agreeChecked: false,
      sendEmailCode: false,
      sendEmailTime: 120,
      sendEmailTimer: null,
      emailExist: false,
      sendPhoneCode: false,
      sendPhoneTime: 60,
      sendPhoneTimer: null,
      phoneExist: false,
      emailErrorMessage: null,
      phoneErrorMessage: null,
      dialogVisible:false,
      imgDialogVisible:false,
      showBrowserInfo:false
    }
  },
  computed: {
    journalName(){
      return this.getUrlParam('name')
    },
    role() {
      switch (this.getUrlParam('app_type')) {
        case 'pub': {
          return '学者'
        }
        case 'pc': {
          return '作者'
        }
        default: {
          return '作者'
        }
      }
    }
  },
  created(){

  },
  mounted() {
    localStorage.setItem("email", this.form.email)
    localStorage.setItem("phone", this.form.phone)
  },
  methods: {
    closeBrowserInfo(){
      this.showBrowserInfo = false
    },
    getUrlParam(key) {
       // 获取参数
      var url = window.location.search
      // 正则筛选地址栏
      var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
      // 匹配目标参数
      var result = url.substr(1).match(reg)
      // 返回参数值
      return result ? decodeURIComponent(result[2]) : null
    },
    async getImageCode(flag){
      let params = ''
      if(flag === 'email'){
        params = this.form.email
        this.$refs.form.validateField("email")
        if (this.form.email.length === 0) {
          return
        }
      }else{
        params = this.form.phone
        this.$refs.form.validateField("phone")
        if (this.form.phone.length === 0) {
          return
        }
      }
      let { status, data } = await API.getImageCode(params)
      if(status === 0){
        this.imgDialogVisible = true
        if(flag === 'email'){
          this.emailVerifyData = data
          this.emailImgVerify = true
        }else{
          this.phoneVerifyData = data
          this.phoneImgVerify = true
        }
      }
    },
    async checkImageCode(code,flag){
      let params = ''
      if(flag === 'email'){
        params = this.form.email
      }else{
        params = this.form.phone
      }
      let { status, data } = await API.checkImageCode(params,code)
      if(status === 0 && data){
        this.imgDialogVisible = false
        this.emailImgVerify = false
        this.phoneImgVerify = false
        if(flag === 'email'){
          this.getEmailCode(code)
        }else{
          this.getPhoneCode(code)
        }
      }else{
        if(flag === 'email'){
          this.getImageCode('email')
        }else{
          this.getImageCode('phone')
        }
      }
    },
    async getEmailCode(code) {
      let { status, data } = await API.emailIsExist(this.form.email)
      if (status === 0 && data) {
        // data.status === 3 邮箱不存在
        // data.status ===1  邮箱存在
        this.emailImgVerify = false
        if (data.status === 3) {
          let { status, message } = await API.getEmailCode(this.form.email,code)
          if (status === 0) {
            this.sendEmailCode = true
            this.sendEmailTimer = setInterval(() => {
              this.sendEmailTime--
              if (this.sendEmailTime === 0) {
                clearInterval(this.sendEmailTimer)
                this.sendEmailTimer = null
                this.sendEmailTime = 120
                this.sendEmailCode = false
              }
            }, 1000)
          }else{
            this.$message.error(message)
          }
          this.emailExist = false
        } else if (data.status === 1) {
          this.emailExist = true
        }else{
          this.$message.error(data.message)
        }
      }else if(status == 1 && !data){
        this.emailExist = true
      }
    },
    async getPhoneCode(code) {
      let { status, data } = await API.phoneIsExist(this.form.phone)
      if (status === 0 && data) {
        // data.status === 3 手机号不存在
        // data.status ===1  手机号存在
        this.phoneImgVerify = false
        if (data.status === 3) {
          let { status, message } = await API.getPhoneCode(this.form.phone,code)
          if (status === 0) {
            this.sendPhoneCode = true
            this.sendPhoneTimer = setInterval(() => {
              this.sendPhoneTime--
              if (this.sendPhoneTime === 0) {
                clearInterval(this.sendPhoneTimer)
                this.sendPhoneTimer = null
                this.sendPhoneTime = 60
                this.sendPhoneCode = false
              }
            }, 1000)
          } else{
            this.$message.error(message)
          }
          this.phoneExist = false
        } else if (data.status === 1) {
          this.phoneExist = true
        }else{
          this.$message.error(data.message)
        }
      }else if(status === 1){
        this.phoneExist = true
      }
    },
    saveEmail() {
      let email = localStorage.getItem("email")
      if (!email) {
        localStorage.setItem("email", this.form.email)
      } else if (email !== this.form.email) {
        clearInterval(this.sendEmailTimer)
        this.sendEmailTimer = null
        this.sendEmailTime = 120
        this.sendEmailCode = false
        localStorage.setItem("email", this.form.email)
      }
    },
    focusEmail(){
      this.emailExist = false
    },
    savePhone() {
      let phone = localStorage.getItem("phone")
      if (!phone) {
        localStorage.setItem("phone", this.form.phone)
      } else if (phone !== this.form.phone) {
        clearInterval(this.sendPhoneTimer)
        this.sendPhoneTimer = null
        this.sendPhoneTime = 60
        this.sendPhoneCode = false
        localStorage.setItem("phone", this.form.phone)
      }
    },
    focusPhone(){
      this.phoneExist = false
    },
    clearEmailMessage() {
      this.emailErrorMessage = null
    },
    clearPhoneMessage() {
      this.phoneErrorMessage = null
    },
    backLogin() {
      window.history.back(-1)
    },
    lookAgree() {
      /* let routeData = this.$router.resolve({
        name: "agree"
      });
      window.open(routeData.href, '_blank'); */
      let env = window.location.host;
      if(env.indexOf('dev') !== -1){
        window.open('https://contributing-show.devx.fzyun.io/loginAgreement.html')
      }else if(env.indexOf('stagex') !== -1 || env.indexOf('testx') !== -1){
        window.open('https://contributing-show.testx.fzyun.io/loginAgreement.html')
      }else{
        window.open('https://contributing-show.portal.founderss.cn/loginAgreement.html')
      }
    },
    lookSecret() {
      let env =  window.location.host;
      if(env.indexOf('dev') !== -1){
        window.open('https://contributing-show.devx.fzyun.io/loginSecret.html')
      }else if(env.indexOf('stagex') !== -1 || env.indexOf('testx') !== -1){
        window.open('https://contributing-show.testx.fzyun.io/loginSecret.html')
      }else{
        window.open('https://contributing-show.portal.founderss.cn/loginSecret.html')
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (!this.agreeChecked) {
            this.$message.error("请确认接受用户协议和隐私政策")
            return
          }
          let key = "JOURNAL@20220110" // 可以自定义
          let iv = "$JL<&*lZFsZ?:p#1" // 偏移量
          let journalId = this.getUrlParam('journal')
          let type = this.getUrlParam('type')
          const password = encrypt(this.form.password, key, iv)
          let params = {
            email: this.form.email,
            emailCode: this.form.emailCode,
            bankupEmail: this.form.bankupEmail,
            password: password,
            phone: this.form.phone,
            realName: this.form.realName,
            verifyCode: this.form.verifyCode
          }
          if(type == 'journal'){
            this.$set(params,'journalId',journalId)
          }else{
            this.$set(params,'groupId',journalId)
          }
          let { status, message } = await API.registerAuthor({
            data: params
          })
          setTimeout(() => {
            if (status === 0) {
              this.backLogin()
            } else if (status === 5008) {
              this.emailErrorMessage = message
              this.$message.error(message)
            } else if (status === 6004) {
              this.phoneErrorMessage = message
              this.$message.error(message)
            } else if(status === 999 || status === 5007 || status === 6002){
              this.$message.error(message)
            } else {
              this.dialogVisible = true
              this.form.emailCode = ''
              this.form.verifyCode = ''
            }
          },1000)
        }
      })
    },
    handleClose(){
      this.dialogVisible = false
      this.imgDialogVisible = false
      this.emailImgVerify = false
      this.phoneImgVerify = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .register {
    &__top {
      height: 60px;
      background: #4054af;
      line-height: 60px;
      color: #ffffff;
      div {
        width: 800px;
        margin: 0 auto;
        padding-left: 80px;
      }
    }
    &__body {
      width: 800px;
      margin: 0 auto;
      &__journal--name{
        font-weight: 400;
        margin:20px 0 20px 0;
        span{
          font-weight: 500;
        }
      }
      &__short{
        width: 50%;
      }
      &__sended{
        color:#aaaaaa !important;
      }
      .el-form {
        width: 100%;
        margin-top: 20px;
        .el-input-group__append {
          border: 1px solid #ececec;
        }
        .el-form-item {
          margin-bottom: 20px;
        }
        .el-form-item__content {
          height: 35px;
          .el-input {
            height: 100%;
            input {
              height: 100%;
              background: #ffffff;
            }
          }
        }
      }
      &__back {
        padding: 15px 0;
        margin-left: 5px;
        cursor: pointer;
        color: #4054af;
        .el-link--inner {
          margin-left: 5px;
        }
      }
      &__agree {
        color: #4054af;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: 500;
        cursor: pointer;
      }
      &__error {
        position: relative;
        top: -8px;
        color: #f56c6c;
        font-size: 12px;
      }
      &__password {
        margin-bottom: 30px !important;
        .el-form-item__error {
          margin-top: 15px;
        }
      }
      &__password--btn {
        width: 100%;
      }
      &__password--prompt {
        position: relative;
        display: inline-block;
        top: -12px;
        font-size: 12px;
        color: #999999;
      }
      &__exist--email {
        position: relative;
        top: -5px;
        font-size: 12px;
      }
      &__exist--email--prompt {
        color: #f56c6c;
      }
      &__exist--email--login {
        color: #4054af;
        cursor: pointer;
        margin-left: 5px;
      }
    }

    ::v-deep {
      .el-dialog {
        .el-dialog__footer {
          .el-button {
            width: 100% !important;
          }
        }

        .el-dialog__body {
          padding-bottom: 100px;
        }

        .dialog-body {
          display: inline-block;
          height: 100px;
        }
      }

      .el-form-item__error {
        padding-top: 8px !important;
      }

      .imgVerify {
        min-height: 45px;
        margin-bottom: 10px !important;

        .el-form-item__content {
          height: auto !important;
          min-height: 35px !important;
        }

        &__img {
          height: 50px;
          margin-left: 30px;
        }

        &__option {
          margin: 30px 50px;

          &--button {
            display: inline-block;
            width: 55px;
            height: 30px;
            background: #4054AF;
            color: #ffffff;
            margin-right: 20px;
            margin-bottom: 10px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
          }

          &--prompt {
            margin-bottom: 10px;
          }
        }

        &__option, &__img, &__change {
          float: left;

          .el-button {
            color: #ffffff;
          }
        }

        &__change {
          margin: 10px 10px;
          cursor: pointer;
        }
      }

      .el-input-group__append {
        .el-button span {
          color: white;
        }
      }

      .el-button {
        background: #4054AF;
        border: 1px solid #4054AF;

        span {
          margin-left: 0 !important;
        }
      }

      .is-disabled:hover {
        cursor: not-allowed !important;
        background: rgba(255, 255, 255, 0);
        border: none;

        span {
          cursor: not-allowed !important;
        }
      }

      .is-disabled {
        border: none
      }

      .el-dialog__headerbtn {
        background: #4054AF;
      }
    }
  }

</style>
